<template>
  <div class="content">
    <div class="title">库存管理</div>
    <a-row class="ptl10">
      <a-col :span="2" class="lh32">订单日期：</a-col>
      <a-col :span="8">
        <a-range-picker @change="onChangeTime">
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
      </a-col>
      <a-col :span="2" class="lh32">供货人：</a-col>
      <a-col :span="3">
        <a-select
          v-model="reqForm.supplierId"
          show-search
          placeholder="请选择供货人"
          style="width:100%"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
        >
          <a-select-option :value="item.id" v-for="item in suppliers" :key="item.code">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="2" class="lh32">品类：</a-col>
      <a-col :span="3">
        <!-- <a-input placeholder="" /> -->
        <a-select
          v-model="reqForm.merchandiseCode"
          show-search
          placeholder="请选择品类"
          style="width:100%"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
        >
          <a-select-option :value="item.code" v-for="item in categorys" :key="item.code">
            {{item.label}}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="2" class="lh32">
        <a-button type="primary" @click="reqForm.pageNum = 1;getWarehouseList()">
           查询
        </a-button>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" class="total-row" v-if="selectedRows.length>0">
      <a-col>
        <a-icon type="check-circle" style="margin-right:10px"/> 商品分类：废钢-边角料，总净重：{{allTotalNetWeight}}吨，总金额：{{allTotalPrice}}元
      </a-col>
      <a-col>
        <a-button type="primary" @click="$router.push(`/stockorder?entityId=${$route.query.entityId}&inIds=${selectedRowKeys.toString()}`)" size="small" class="create-btn">
          <a-icon type="right-square"></a-icon> 创建订单
        </a-button>
      </a-col>
    </a-row>

    <a-table
      style="margin-top:15px"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
      :expanded-row-keys.sync="expandedRowKeys"
      :pagination="{
          size: 'middle',
          total: total,
          current: reqForm.pageNum,
          pageSize: reqForm.pageSize,
          showTotal: total => `共有 ${total} 条数据`,
          showSizeChanger: true,
          showQuickJumper: true,
          onShowSizeChange: (current, pageSize) => {
            reqForm.pageSize = pageSize
            reqForm.pageNum = 1
            this.getWarehouseList()
          },
          onChange: (pageNum) => {
            reqForm.pageNum = pageNum
            this.getWarehouseList()
          }
        }"
    >
      <template slot="supplierName" slot-scope="text,record">
        <a @click="getWarehouseInfo(record.id)">{{ text }}</a>
      </template>
    </a-table>

    <a-modal
      title="入库详情"
      :visible="visible"
      :footer="null"
      :width="800"
      @cancel ='visible=false'
    >
      <div>
        <div>
          <a-row justify="space-between" type="flex">
            <a-col >
              <span class="pop-title">入库单号：{{wInfo.id}}</span>
            </a-col>
            <a-col>
              <a-popconfirm :title="`是否删除?`" @confirm="onHandlerDelete(wInfo.id)">
                <a-button type="primary" @click="visible = true" size="small" class="create-btn">
                   删除入库
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
          <div class="pop-detail">
            <a-row >
              <a-col :span="10">
                供应商：{{wInfo.supplierName}}
              </a-col>
              <a-col :span="10">
                  企业统一代码：{{wInfo.uniformCode}}
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                收货下游：{{wInfo.downstreamName}}
              </a-col>
              <a-col :span="10">
                  入库仓库：{{wInfo.warehouseAddress}}
              </a-col>
            </a-row>
            <div class="pic-col">
              质检照片： 
              <img :src="pic.imageUrl" class="z_pic" v-for="(pic,index) in wInfo.quantityPic" :key="index" height="100" width="200" style="object-fit: cover"> 
            </div>
            <a-row >
              <a-col :span="8">
                一级分类：{{wInfo.categoryFirstValue}}
              </a-col>
              <a-col :span="8">
                二级分类：{{wInfo.categorySecondValue}}
              </a-col>
              <!-- <a-col :span="8">
                规格：{{wInfo.secLabel}}
              </a-col> -->
            </a-row>
            <a-row >
              <a-col :span="8">
                车牌号：{{wInfo.licenseNum}}
              </a-col>
              <a-col :span="8">
                净重：{{wInfo.netWeight}}吨
              </a-col>
              <a-col :span="8">
                收货价：{{wInfo.totalPrice}} 元
              </a-col>
            </a-row>
          </div>
          <a-row justify="space-between" type="flex">
            <a-col class="pop-title">
              入库详情
            </a-col>
          </a-row>
          <div class="pop-detail">
            <a-row >
              <a-col :span="10">
                毛重：{{wInfo.firstWeight}}吨
              </a-col>
              <a-col :span="10">
                称重时间：{{wInfo.firstWeighTime}}
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                皮重：{{wInfo.secondWeight}}吨
              </a-col>
              <a-col :span="10">
                称重时间：{{wInfo.secondWeighTime}}
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                扣杂：{{wInfo.impuritiesWeight}}吨
              </a-col>
              <a-col :span="10">
                审核人员：{{wInfo.impuritiesUserName}}
              </a-col>
            </a-row>
            <a-row style="margin-top:15px">
              <a-col :span="6" style="padding:0 10px" v-for="(pic,index) in wInfo.weighListPic" :key="index">
                <img :src="pic.imageUrl" width="100%" height="100" style="object-fit: cover">
              </a-col>
              <a-col :span="6" style="padding:0 10px" v-for="(pic,index) in wInfo.weighingPic" :key="index">
                <img :src="pic.imageUrl" width="100%" height="100" style="object-fit: cover">
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </a-modal>

  </div>
</template>
<script>
import { warehouseList, categorysList, suppliersList, warehouseInfo, warehouseDelete } from '@/api/buyerCenter'
const columns = [
  {
    title: '供货人',
    dataIndex: 'supplierName',
    key: 'supplierName',
    scopedSlots: { customRender: 'supplierName' },
  },
  {
    title: '商品分类',
    dataIndex: 'catValue',
    key: 'catValue',
  },
  {
    title: '车牌号',
    dataIndex: 'licenseNum',
    key: 'licenseNum',
  },
  {
    title: '毛重(吨)',
    dataIndex: 'firstWeight',
    key: 'firstWeight',
  },
  {
    title: '皮重(吨)',
    dataIndex: 'secondWeight',
    key: 'secondWeight',
  },
  {
    title: '扣杂(吨)',
    dataIndex: 'impuritiesWeight',
    key: 'impuritiesWeight',
  },
  {
    title: '净重(吨)',
    dataIndex: 'netWeight',
    key: 'netWeight',
  },
  {
    title: '单价',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
  },
  {
    title: '收货金额',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
  },
  {
    title: '入库时间',
    dataIndex: 'inTime',
    key: 'inTime',
  },
];

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
//   onSelect: (record, selected, selectedRows) => {
//     console.log(record, selected, selectedRows);
//   },
//   onSelectAll: (selected, selectedRows, changeRows) => {
//     console.log(selected, selectedRows, changeRows);
//   },
// };
export default {
  data() {
    return {
      data:[],
      columns,
      total: 0,
      expandedRowKeys: [],
      categorys:[],//筛选类别列表
      visible: false,
      suppliers:[],//筛选供应商列表
      reqForm:{
        pageNum:1,
        pageSize:10,
        purchaserId: this.$route.query.entityId,
        from:'',
        to:'',
        supplierId:'',
        merchandiseCode:''
      },
      wInfo:{},
      selectedRows:[], //选中订单
    };
  },
  mounted(){
    this.getWarehouseList()
    this.getCategorysList()
    this.getSuppliersList()
  },
  computed:{
    //总金额
    allTotalPrice(){
      if(!this.selectedRows.length){
        return 0
      }
      let price = this.selectedRows.reduce((prev,next)=>{
        prev += next.totalPrice
        return prev
      },0)
      return price.toFixed(2)
    },
    //总金额
    allTotalNetWeight(){
      if(!this.selectedRows.length){
        return 0
      }
      let netWeight = this.selectedRows.reduce((prev,next)=>{
        prev += next.netWeight
        return prev
      },0)
      return netWeight.toFixed(0)
    },
    rowSelection() {
      return {
        columnTitle: ' ',
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
          //重新赋值刷新table的data
          this.data = [...this.data]
        },
        getCheckboxProps: (recode) => {
          let selectedRow_ = this.selectedRows[0]||{}
          return {
            props: {
              //当选择了订单后 供货人、商品分类、单价不一致都不能一起选择去支付
              disabled: this.selectedRows.length>0&&(selectedRow_.supplierName!==recode.supplierName||selectedRow_.catValue!==recode.catValue||selectedRow_.unitPrice!==recode.unitPrice)
            },
          }
        },
      };
    },
  },
  methods: {
    filterOption(input,option){
      console.log(input)
      console.log(option)
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onChangeTime(date, dateString) {
      this.reqForm.beginTime = dateString[0]
      this.reqForm.endTime = dateString[1]
      // this.getlist()
    },
    //删除
    onHandlerDelete(id){
      warehouseDelete(id).then(res=>{
        if(res.code==200){
          this.visible = false;
          this.getWarehouseList()
          this.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //查看详情
    getWarehouseInfo(id){
      this.visible = true
      warehouseInfo(id).then(res=>{
        if(res.code==200){
          this.wInfo = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //供应商列表
    getSuppliersList(){
      let {entityId} = this.$route.query
      suppliersList({
        purchaserId:entityId
      }).then(res=>{
        if(res.code==200){
          this.suppliers = [{id: "", name: "全部"},...res.data]
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //获取对应类别列表
    getCategorysList(){
      let {entityId} = this.$route.query
      categorysList({
        purchaserId:entityId
      }).then(res=>{
        if(res.code==200){
          this.categorys = [{code: "", label: "全部"},...res.data]
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getWarehouseList(){
      warehouseList(this.reqForm).then(res=>{
        if(res.code==200){
          this.data = res.data.list.map(item=>{
            item.key =item.id
            return item
          })
          this.total = res.data.total
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  padding: 20px 10px 20px 20px;
  background-color: #ffffff;
  .title {
    padding-left: 10px;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 28px;
  }
  .ant-row {
    padding: 20px 0 0 20px;
  }
  .lh32{
    line-height: 30px;
    text-align: center;
  }
  .total-row{
    margin: 15px 20px 0;
    padding: 10px;
    background-color: #80ffff;
    border-radius: 5px;
    .ant-col{
      display: flex;
      align-items: center;
    }
  }
  .create-btn{
    background-color: rgba(132, 0, 255, 0.7);
    border-color: rgba(132, 0, 255, 0.7);
  }
}
.pop-title{
  font-weight: bold;
  color: #000;
}
.pic-col{
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  .z_pic{
    margin-left: 10px;
  }
}
.pop-detail{
  margin-bottom: 15px;
  .ant-col{
    margin-top: 5px;
  }
}
</style>
